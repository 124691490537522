import { DateTimeFormats, NumberFormats } from '@intlify/core-base'

export const datetimeFormats = {
  de: {
    monthYearOnly: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    input: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
  },
} as DateTimeFormats

export const numberFormats = {
  de: {
    currency: {
      notation: 'standard',
    },
    eur: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    kilometer: {
      style: 'unit',
      unit: 'kilometer',
      notation: 'standard',
    },
    horsepower: {
      notation: 'standard',
    },
  },
} as NumberFormats
